
import { defineComponent } from "vue";
import AppPage from "@/components/AppPage.vue";
import Button from "@/components/Button.vue";
import DataTable from "@/components/DataTable.vue";
import TextInput from "@/components/TextInput.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import Daterange from "@/components/Daterange.vue";
import Editor from "./Editor.vue";

import axios from "axios";
import moment, { Moment } from "moment-timezone";
import { Pagination, Sorting } from "@/use/types";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  definition: any;
  affiliates: Array<Affiliate>;
  notification: Notification | null;
  actionsIndex: number | null;
}

interface Notification {
  title: string;
  body: string;
  affiliate_ids: any[] | null;
}

interface Affiliate {
  id: number;
  name: string;
}

interface AffiliateID {
  id: number;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  is_removed: boolean;
  affiliate_ids: Array<AffiliateID>;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    is_removed: false,
    affiliate_ids: [],
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата нотификации",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "affiliate_ids",
    name: "id веба",
    selected: true,
    sortable: false,
  },
  {
    id: "title",
    name: "Заголовок нотификации",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "body",
    name: "Текст нотификации",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
  },
];

export default defineComponent({
  components: {
    AppPage,
    Button,
    TextInput,
    Daterange,
    DataTable,
    SubsetInput,
    Editor,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      affiliates: [],
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      notification: null,
      actionsIndex: null,
    };
  },
  async created() {
    this.load();
    this.getAffiliates();
    await this.getPeriodLimits();
  },
  mounted: function () {
    this.$watch("filter.is_removed", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  methods: {
    close() {
      this.notification = null;
    },
    getIconName(types: any) {
      if (
        types.from_admins_removed_at &&
        types.from_affiliates_removed_at === null
      ) {
        return "img/remove_admin_icon.svg";
      } else if (
        types.from_admins_removed_at === null &&
        types.from_affiliates_removed_at
      ) {
        return "img/remove_affiliates_icon.svg";
      } else {
        return "img/remove_icon.svg";
      }
    },
    async getPeriodLimits() {
      const { data } = await axios("/api/get-period-limits/notifications");
      this.filter.period = [
        data.min || moment().endOf("day"),
        moment().endOf("day"),
      ];
    },
    showActions(id: number, e: any) {
      const { actions } = this.$refs;

      if (this.actionsIndex === id) {
        this.actionsIndex = null;
      } else {
        if (!(actions as any).classList.contains("_active")) {
          const target = e.target;
          const options = target.nextSibling;
          const itemOffsetTop = target.getBoundingClientRect().top;
          const containerHeight = target.offsetHeight;
          const top = containerHeight + itemOffsetTop + "px";

          options.style.top = top;
        } else {
          (actions as any).forEach((el: any) => el.removeAttribute("style"));
        }

        this.actionsIndex = id;
      }

      window.addEventListener("scroll", () => {
        if (actions) {
          (actions as any).classList.remove("_active");
          this.actionsIndex = null;
        }
      });
    },
    async removeNotification(id: number, remove_from: string[]) {
      try {
        await axios.post(`/api/notifications/remove/${id}`, {
          columns: JSON.stringify(remove_from),
        });
        this.load();
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getPeriodLimits();
        return;
      }
      this.filter.period = value;
    },
    async searchAffiliate(query: string) {
      const { data } = await axios.get("/api/affiliates/lookup", {
        params: {
          q: JSON.stringify({
            query,
          }),
        },
      });

      this.affiliates = data;
    },
    async getAffiliates() {
      const { data } = await axios.get("/api/affiliates/lookup");
      this.affiliates = data;
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    updateAffiliatesAndLoad(e: any) {
      this.filter.affiliate_ids = e;
    },
    openEditor() {
      this.notification = {
        title: "",
        body: "",
        affiliate_ids: [],
      };
    },
    async clear() {
      this.filter = getDefaultQuery();
      await this.getPeriodLimits();
      this.load();
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const { data } = await axios.get("/api/notifications", {
          params: { q: JSON.stringify(this.filter) },
        });
        const formedArray = data.rows.map((el: any) => ({
          ...el,
          affiliate_ids: el.affiliate_ids.join(", "),
        }));
        this.rows = formedArray;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});

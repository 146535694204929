
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import TipTap from "@/components/TipTap.vue";
import Button from "@/components/Button.vue";
import SubsetInput from "@/components/SubsetInput.vue";

import axios from "axios";

export default defineComponent({
  components: {
    Popup,
    TextInput,
    TipTap,
    Button,
    SubsetInput,
  },
  props: {
    notification: Object,
  },
  emits: ["update:notification", "close", "save"],
  data() {
    return {
      loading: false,
      options: {
        affiliates: [],
      },
      body: this.notification?.body,
      errors: {} as { [key: string]: string | null },
    };
  },
  created() {
    this.searchAffiliate("");
  },
  watch: {
    body(value) {
      this.$emit("update:notification", {
        ...this.notification,
        body: value,
      });
      this.errors.body = "";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async searchAffiliate(query: string) {
      const { data } = await axios.get(`/api/affiliates/lookup`, {
        params: {
          q: JSON.stringify({
            query,
          }),
        },
      });

      this.options.affiliates = data;
    },
    emitPatch(patch: any) {
      this.$emit("update:notification", {
        ...this.notification,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async searchData(query: string, collection: string) {
      const { data } = await axios.get(`/api/${collection}/lookup`, {
        params: {
          q: JSON.stringify({
            query,
          }),
        },
      });

      const key: "affiliates" = collection as any;
      this.options[key] = data;
    },
    async sendNotification() {
      try {
        await axios.post("/api/notifications", this.notification);

        this.$emit("save");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
